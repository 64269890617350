import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { TestExecution, TestExecutor, Application, TestFailureGroup, ProgressBarSegment, ProgressBarSegmentType, TestRunSummary, TestCase, TestCaseSummary, NetworkTrendsResponse, NetworkOperationNetworkSegment, NetworkTrendType, NetworkFilter, NetworkDimension, NetworkSegment, NetworkTrendsSummaryResponse } from 'app/_models';
import { TestRunResult } from 'app/_models/test.run.result';
import { TestExecutionsService, AlertService, ApplicationService, TestExecutorsService, TestExecutionDetails, TestExecutionMetricsSummary, NetworkOperationsService, CollectionResponse, TestExecutorAllocation } from 'app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { TestsFilter } from 'app/_models/tests.filter';
import { ApplicationFormatter, DateFormatter, PlatformFormatter, IssueStateFormatter } from 'app/_helpers';
import { Observable, Subscription, timer } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { trigger } from '@angular/animations';
import { Animations } from 'app/_animations/animations';
import { EditNetworkOperationModal } from 'app/network/edit.network.operation.modal/edit.network.operation.modal';
import * as moment from 'moment';
import { RequestPreviewModal } from 'app/network/request.preview.modal/request.preview.modal';

@Component({
    selector: 'test-execution',
    templateUrl: 'test.execution.component.html',
    styleUrls: [
        'test.execution.component.css'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('fadeInAnimation', Animations.fadeIn)
    ]
})
export class TestExecutionComponent implements OnInit, OnDestroy {

    NetworkDimension = NetworkDimension
    TestsFilter = TestsFilter

    application: Application
    testExecution: TestExecution
    testExecutionDetails: TestExecutionDetails
    testExecutionMetricsSummary: TestExecutionMetricsSummary

    testFailureGroups: TestFailureGroup[]
    testExecutorAllocations: TestExecutorAllocation[]

    networkTrendType: NetworkTrendType
    networkDimension: NetworkDimension = NetworkDimension.Overview
    networkTrendsResponse: NetworkTrendsResponse
    networkTrendsSummaryResponse: NetworkTrendsSummaryResponse
    networkSegments: NetworkSegment[]

    loadedAllData = false

    // Indicates that some test cases were ran multiple times (either being restarted after failure or being run in multiple configurations)
    hasTestCasesWithMultipleRuns = false
    hasTestConfigurations = false
    compactExecutorsMap = false

    selectedTestsFilter: TestsFilter = TestsFilter.All

    filteredTestCases: TestCaseSummary[]
    allTestRuns: Array<TestRunSummary>

    private testCaseRunsMap: Map<number, Array<TestRunSummary>>
    private testCasesMap: Map<number, TestCaseSummary>
    private testFailureGroupRunsMap: Map<number, Array<TestRunSummary>>
    private testExecutorsMap: Map<number, TestExecutor>

    testsDuration: string
    successRate: string
    // percentagePassed: string
    // percentageFailed: string

    private queryParamsSubscription: Subscription
    private currentApplicationSubscription: Subscription

    private timerObservable: Observable<number>
    private timerSubscription: Subscription | null

    @ViewChild('editNetworkOperationModal') editNetworkOperationModal: EditNetworkOperationModal
    @ViewChild('requestPreviewModal') requestPreviewModal: RequestPreviewModal

    constructor(
        public dateFormatter: DateFormatter,
        public issueStateFormatter: IssueStateFormatter,
        public platformFormatter: PlatformFormatter,
        private changeDetector: ChangeDetectorRef,
        private testExecutionsService: TestExecutionsService,
        private testExecutorsService: TestExecutorsService,
        private networkOperationsService: NetworkOperationsService,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private applicationService: ApplicationService,
        private router: Router,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        let query = this.route.snapshot.queryParams
        this.selectedTestsFilter = query['filter'] ?? TestsFilter.All
        this.networkTrendType = query['networkTrendType'] ?? NetworkTrendType.Appdex
        this.networkDimension = query['dimension'] ?? NetworkDimension.Overview

        this.queryParamsSubscription = this.route.queryParams.subscribe(params => {

            // If network type changed, it means this is the only change
            let newNetworkTrendType = params['networkTrendType'] ?? NetworkTrendType.Appdex
            if (this.networkTrendType != newNetworkTrendType) {
                this.networkTrendType = newNetworkTrendType
                this.refreshNetworkTrendsIfNeeded()
                return
            }

            // If network dimension changed, it means this is the only change
            let newNetworkDimension = params['dimension'] ?? NetworkDimension.Overview
            if (this.networkDimension != newNetworkDimension) {
                this.networkDimension = newNetworkDimension
                this.reloadNetworkDimensionsIfNeeded()
                return
            }

            this.selectedTestsFilter = params['filter'] ?? TestsFilter.All

            this.updateData()
            this.changeDetector.detectChanges()

            this.refreshNetworkTrendsIfNeeded()
            this.reloadNetworkDimensionsIfNeeded()
        })

        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null) {
                let testExecutionSlug = this.route.snapshot.params['testExecutionSlug']
                this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Test Execution #${testExecutionSlug}`)
                this.reloadTestExecutionDetails(testExecutionSlug)
            }
        })
    }

    ngOnDestroy() {
        this.queryParamsSubscription.unsubscribe()
        this.currentApplicationSubscription.unsubscribe()
        this.timerSubscription?.unsubscribe()
    }

    onNetworkTrendTypeChanged(networkTrendType: NetworkTrendType) {
        this.router.navigate([], { queryParams: { networkTrendType: networkTrendType }, queryParamsHandling: 'merge' })
    }

    private reloadTestExecutionDetails(testExecutionSlug: string) {
        this.testExecutionsService.getApplicationTestExecutionWithSlug(this.application.id, testExecutionSlug).then((response) => {
            this.testExecution = response.data
            let detailsPromise = this.testExecutionsService.getTestExecutionDetails(this.testExecution.id)
            let metricsPromise = this.testExecutionsService.getTestExecutionMetricsSummary(this.testExecution.id)

            this.refreshNetworkTrendsIfNeeded()
            this.reloadNetworkDimensionsIfNeeded()

            return Promise.all([detailsPromise, metricsPromise])

        }).then((response) => {
            this.testExecutionDetails = response[0].data
            this.testExecutorAllocations = this.testExecutionDetails.testExecutorAllocations
            this.testExecutionMetricsSummary = response[1]

            this.updateData()
            this.loadedAllData = true

            // If test execution is not yet finished, schedule an update timer
            if (this.testExecution.endDate == null) {
                if (this.timerObservable == null) {
                    this.timerObservable = timer(0, 5000)
                    this.timerSubscription = this.timerObservable.subscribe((seconds) => {
                        if (seconds < 1) { return } // Ignore the initial call
                        this.reloadTestExecutionDetails(testExecutionSlug)
                    })
                }

            } else {
                this.timerSubscription?.unsubscribe()
            }

        }).catch((error) => {
            this.alertService.handleError(error)
        }).finally(() => {
            this.changeDetector.detectChanges()
        })
    }

    private refreshNetworkTrendsIfNeeded() {
        if (!this.testExecution || this.selectedTestsFilter != TestsFilter.Network) { return }

        // Will also switch the trends chart into loading mode
        this.networkTrendsResponse = null
        this.networkTrendsSummaryResponse = null

        let networkFilter = this.makeCurrentNetworkFilter()

        this.networkOperationsService.getNetworkTrends(this.application.workspaceId, this.networkTrendType, networkFilter).then((response) => {
            this.networkTrendsResponse = response
            if (response.summary) {
                this.networkTrendsSummaryResponse = response.summary
            } else {
                this.networkOperationsService.getNetworkTrendsSummary(this.application.workspaceId, this.networkTrendType, networkFilter).then((response) => {
                    this.networkTrendsSummaryResponse = response
                    this.changeDetector.detectChanges()
                })
            }
        }).catch((error) => {
            this.alertService.handleError(error)
        }).finally(() => {
            this.changeDetector.detectChanges()
        })
    }

    private reloadNetworkDimensionsIfNeeded() {
        if (!this.testExecution || this.selectedTestsFilter != TestsFilter.Network) { return }

        let networkFilter = this.makeCurrentNetworkFilter()
        this.networkOperationsService.getNetworkSegments(this.application.workspaceId, this.networkDimension, networkFilter).then((response) => {
            if (response) {
                this.networkSegments = (response as CollectionResponse<NetworkSegment>).data
            }

        }).catch((error) => {
            this.alertService.handleError(error)

        }).finally(() => {
            this.changeDetector.detectChanges()
        })
    }

    private makeCurrentNetworkFilter(): NetworkFilter {
        let networkFilter = new NetworkFilter()
        networkFilter.startDate = moment(this.testExecution.creationDate).toDate()
        if (this.testExecution.endDate) {
            networkFilter.endDate = moment(this.testExecution.endDate).toDate()
        } else {
            // Subtract 2 minutes in order to not to show very rough or empty data
            // as the requests aggregation runs only once a minute
            networkFilter.endDate = moment().subtract(90, 'seconds').toDate()
        }
        networkFilter.testExecutionId = this.testExecution.id
        return networkFilter
    }

    private updateData() {
        if (this.testExecutionDetails == null) {
            return
        }

        this.testCaseRunsMap = new Map([])
        this.testFailureGroupRunsMap = new Map([])
        this.testExecutorsMap = new Map([])
        this.testCasesMap = new Map([])
        this.filteredTestCases = []
        this.testFailureGroups = []
        this.hasTestCasesWithMultipleRuns = false
        this.hasTestConfigurations = false

        this.testsDuration = this.dateFormatter.duration(this.testExecution.stats.duration)
        this.successRate = (this.testExecution.stats.successRate * 100).toFixed(1) + '%'

        // Cache the TestExecutors
        for (let allocationInfo of this.testExecutorAllocations) {
            let testExecutor = allocationInfo.testExecutor
            this.testExecutorsMap.set(testExecutor.id, testExecutor)
        }

        this.compactExecutorsMap = this.testExecutorAllocations.length > 8

        for (let testRun of this.testExecutionDetails.testRuns) {

            // Update the flag about test configurations
            if (this.hasTestConfigurations == false && testRun.testConfiguration != null) {
                this.hasTestConfigurations = true
            }

            // Group based on the testCaseId
            var testRuns: TestRunSummary[] = this.testCaseRunsMap.get(testRun.testCaseId)
            if (testRuns == null) {
                testRuns = []
            }
            testRuns.push(testRun)
            this.testCaseRunsMap.set(testRun.testCaseId, testRuns)

            // Group based on testFailureGroupId
            if (testRun.testFailureGroupId != null) {
                var testRuns: TestRunSummary[] = this.testFailureGroupRunsMap.get(testRun.testFailureGroupId)
                if (testRuns == null) {
                    testRuns = []
                }
                testRuns.push(testRun)
                this.testFailureGroupRunsMap.set(testRun.testFailureGroupId, testRuns)
            }
        }

        for (let testCase of this.testExecutionDetails.testCases) {
            this.testCasesMap.set(testCase.id, testCase)

            if (this.hasTestCasesWithMultipleRuns == false && this.testRunsForTestCase(testCase).length > 1) {
                this.hasTestCasesWithMultipleRuns = true
            }

            // Filter based on the selectedTestsFilter,
            // for failures we have a different view.
            if (this.selectedTestsFilter == TestsFilter.All) {
                this.filteredTestCases.push(testCase)
            }
        }

        // Sort testCases
        this.filteredTestCases.sort((tc1, tc2) => {
            let name1 = `${tc1.suiteName}.${tc1.name}`
            let name2 = `${tc2.suiteName}.${tc2.name}`
            return name1.localeCompare(name2)
        })

        // Sort testFailureGroups based on the failures count
        this.testFailureGroups = this.testExecutionDetails.testFailureGroups.sort((g1, g2) => {
            let n1 = this.numberOfFailuresForTestFailureGroup(g1.id)
            let n2 = this.numberOfFailuresForTestFailureGroup(g2.id)
            return n2 - n1
        })

        // Cache all TestRuns
        this.allTestRuns = this.testExecutionDetails.testRuns.sort((tr1, tr2) => {
            let name1 = this.testCaseNameForTestRun(tr1)
            let name2 = this.testCaseNameForTestRun(tr2)
            return name1.localeCompare(name2)
        })

        // this.updateProgress()
    }

    onEditNetworkOperationClick(networkOperationSegment: NetworkOperationNetworkSegment) {
        this.editNetworkOperationModal.networkOperationId = networkOperationSegment.id
        this.changeDetector.detectChanges()
    }

    onEditNetworkOperation(networkOperationId: number) {
        this.editNetworkOperationModal.networkOperationId = networkOperationId
    }

    onNetworkOperationUpdated() {
        this.reloadNetworkDimensionsIfNeeded()
    }

    onFindNetworkLog(networkSegment: NetworkSegment) {
        let networkFilter = this.makeCurrentNetworkFilter()
        this.requestPreviewModal.findByNetworkFilter(networkFilter, networkSegment)
    }

    setTestsFilter(testsFilter: TestsFilter) {
        // Reset to the default one in order to skip change detection in query
        if (testsFilter != TestsFilter.Network) {
            this.networkTrendType = NetworkTrendType.Appdex
            this.networkTrendsResponse = null
        }
        this.router.navigate([], { queryParams: { filter: testsFilter } })
    }

    setNetworkDimension(networkDimension: NetworkDimension) {
        this.networkSegments = null
        this.router.navigate([], { queryParams: { dimension: networkDimension }, queryParamsHandling: 'merge' })
    }

    testRunsForTestCase(testCase: TestCaseSummary): TestRunSummary[] {
        return this.testCaseRunsMap.get(testCase.id)
    }

    testRunsForTestFailureGroup(testFailureGroupId: number): TestRunSummary[] {
        return this.testFailureGroupRunsMap.get(testFailureGroupId)
    }

    numberOfFailuresForTestFailureGroup(testFailureGroupId: number): number {
        return this.testRunsForTestFailureGroup(testFailureGroupId).length
    }

    // TODO: Try:
    // https://lukeliutingchun.medium.com/angular-why-you-should-and-how-to-avoid-function-prototype-bind-in-template-c9b712423d52
    statusClassForTestRun(testRun: TestRunSummary): string {
        switch (testRun.result) {
            case TestRunResult.Success: return `badge badge-mark ${testRun.iteration == testRun.totalIterations ? 'border-success-400' : 'border-success-300'}`
            case TestRunResult.Failure: return `badge badge-mark ${testRun.iteration == testRun.totalIterations ? 'border-pink-400' : 'border-pink-200'}`
            case TestRunResult.Pending: return "badge badge-mark border-grey-400"
            case TestRunResult.InProgress: return "badge badge-mark border-grey-400"
            case TestRunResult.Skipped: return "badge badge-mark border-grey-400"
            case TestRunResult.Aborted: return "badge badge-mark border-slate-600"
        }
    }

    classForProgressBarSegment(segment: ProgressBarSegment): string {
        switch (segment.type) {
            case ProgressBarSegmentType.Blank:
                return ""

            case ProgressBarSegmentType.TestInProgress:
                return "progress-bar progress-bar-striped progress-bar-animated bg-slate-200 handcursor"

            case ProgressBarSegmentType.TestPassPreliminary:
                var showAsDisabled = this.selectedTestsFilter == TestsFilter.Failed
                return showAsDisabled ? "progress-bar progress-bar-striped bg-slate-200 handcursor" : "progress-bar progress-bar-striped bg-success-200 handcursor"

            case ProgressBarSegmentType.TestPassFinal:
                var showAsDisabled = this.selectedTestsFilter == TestsFilter.Failed
                return showAsDisabled ? "progress-bar progress-bar-striped bg-slate-200 handcursor" : "progress-bar progress-bar-striped bg-success-400 handcursor"

            case ProgressBarSegmentType.TestFailurePreliminary:
                return "progress-bar progress-bar-striped bg-pink-200 handcursor"

            case ProgressBarSegmentType.TestFailureFinal:
                return "progress-bar progress-bar-striped bg-pink-400 handcursor"

            case ProgressBarSegmentType.TestAborted:
                return "progress-bar progress-bar-striped bg-slate-400 handcursor"

            case ProgressBarSegmentType.TestCase:
                if (segment.size > 0.4) return "progress-bar progress-bar-striped bg-orange-800"
                else if (segment.size > 0.25) return "progress-bar progress-bar-striped bg-orange-400"
                return "progress-bar progress-bar-striped bg-success-400"
        }
    }

    showLabelForProgressBarSegment(segment: ProgressBarSegment): boolean {
        return segment.size > 0.25
    }

    statusTextForTestRun(testRun: TestRunSummary): string {
        switch (testRun.result) {
            case TestRunResult.Success: return "Passed"
            case TestRunResult.Failure: return testRun.failureDescription
            case TestRunResult.Pending: return "Pending"
            case TestRunResult.InProgress: return "Executing"
            case TestRunResult.Skipped: return "Skipped"
            case TestRunResult.Aborted: return "Aborted"
        }
    }

    testCaseNameForTestRun(testRun: TestRunSummary): string {
        let testCase = this.testCasesMap.get(testRun.testCaseId)
        return `${testCase.suiteName}.${testCase.name}`
    }

    totalFailuresCount(networkOperationSegment: NetworkOperationNetworkSegment): number {
        return networkOperationSegment.clientErrors + networkOperationSegment.serverErrors
    }

    testExecutorForTestRun(testRun: TestRunSummary): TestExecutor {
        return this.testExecutorsMap.get(testRun.testExecutorId)
    }

    testRunDuration(testRun: TestRunSummary): string {
        return (testRun.duration !== undefined) ? this.dateFormatter.duration(testRun.duration) : "-"
    }

    navigateToTestRun(testRun: TestRunSummary) {
        this.router.navigate(['runs', testRun.serialNumber], { relativeTo: this.route })
    }

    navigateToTestFailureGroup(testFailureGroup: TestFailureGroup) {
        this.router.navigate(['../../test-failure-groups', testFailureGroup.serialNumber], { relativeTo: this.route })
    }

    navigateToProgressBarSegmentTestRun(segment: ProgressBarSegment) {
        if (segment.ref == null) { return }
        let testRunSerialNumber = parseInt(segment.ref)

        let testRun = this.testExecutionDetails.testRuns.find((element) => { return element.serialNumber == testRunSerialNumber })
        if (testRun == null) { return }

        this.navigateToTestRun(testRun)
    }

    testCaseTestRuns(testCaseId: number): TestRunSummary[] {
        return this.testCaseRunsMap.get(testCaseId)
    }

    // private updateProgress() {
    //     let stats = this.testExecution.stats

    //     if (this.testExecution.endDate == null) {
    //         this.percentagePassed = (stats.passed / stats.total) * 100 + "%"
    //         this.percentageFailed = (stats.failed / stats.total) * 100 + "%"

    //     } else {
    //         // Once the test execution is finished, there are no more pending and in progress test runs
    //         this.percentagePassed = stats.successRate * 100 + "%"
    //         this.percentageFailed = (1 - stats.successRate) * 100 + "%"
    //     }
    // }

    // private calculateTestCaseResult(testCaseId: number): TestCaseResult {
    //     let testRuns = this.testCaseTestRuns(testCaseId)

    //     var succeededCount = 0
    //     var failedCount = 0
    //     for (let testRun of testRuns) {
    //         if (testRun.result == TestRunResult.Success) {
    //             succeededCount += 1
    //         } else if (testRun.result == TestRunResult.Failure) {
    //             failedCount += 1
    //         }
    //     }

    //     let totalCount = failedCount + succeededCount

    //     if (succeededCount == totalCount) { return TestCaseResult.Success
    //     } else if (failedCount == totalCount) { return TestCaseResult.Failure
    //     } else { return TestCaseResult.Mixed }
    // }

}
