import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AlertService, ApplicationDistributionsService, AuthenticationService } from 'app/_services'
import { ActivatedRoute } from '@angular/router';
import { ApplicationDistribution, BuildDistribution, Workspace } from 'app/_models';
import { Subscription } from 'rxjs';
import { DateFormatter, PlatformFormatter } from 'app/_helpers';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    templateUrl: 'application.install.component.html',
})
export class ApplicationInstallComponent implements OnInit, OnDestroy {

    workspace: Workspace | null
    applicationDistributions: ApplicationDistribution
    builds: BuildDistribution[]
    distributionUrl: string

    private routeParamsSubscription: Subscription

    @ViewChild('pageContent') pageContent: ElementRef

    constructor(
        public platformFormatter: PlatformFormatter,
        public dateFormatter: DateFormatter,
        private applicationDistributionsService: ApplicationDistributionsService,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit() {
        this.routeParamsSubscription = this.route.params.subscribe(params => {
            this.workspace = this.authenticationService.currentWorkspaceValue
            this.reloadApplicationDistribution()
        })
    }

    ngOnDestroy() {
        this.routeParamsSubscription.unsubscribe()
    }

    buildInstallUrl(build: BuildDistribution): SafeUrl {
        if (build.installUrl == null) {
            return null
        }

        return this.sanitizer.bypassSecurityTrustUrl(build.installUrl.toString())
    }

    isOnMobile(): boolean {
        const userAgent = navigator.userAgent || navigator.vendor
        return /android|iPad|iPhone/i.test(userAgent)
    }

    onHowToInstall() {
        alert("Open the current page on your mobile device by scanning the above QR code and then tap on the install button.")
    }

    private reloadApplicationDistribution() {
        this.applicationDistributions = null
        this.builds = null

        let applicationSlug = this.route.snapshot.params['applicationSlug']
        let workspaceSlug = this.route.snapshot.params['workspaceSlug']

        this.applicationDistributionsService.resolveApplicationDistribution(workspaceSlug, applicationSlug).then((response) => {
            this.applicationDistributions = response.data
            this.distributionUrl = window.location.href
            this.pageContent.nativeElement.scrollTo(0, 0)
            this.reloadBuilds()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private reloadBuilds() {
        if (!this.applicationDistributions) {
            return
        }

        this.applicationDistributionsService.getApplicationDistributionBuilds(this.applicationDistributions.id).then((response) => {
            this.builds = response.data
        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
