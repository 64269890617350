import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from 'app/_services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            // Auto logout if 401 response returned from api.
            // Exclude /authenticate because that one genuinely returns 401 if credentials don't match
            // Exclude /logout in order to prevent infinite recursion
            if (err.status === 401 && !request.url.endsWith("/authenticate") && !request.url.endsWith("/logout")) {
                this.authenticationService.logout()
                this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } })
            }

            return throwError(err)
        }))
    }

}
